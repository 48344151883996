<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container text-center">
            <div v-if="!isLoading && storyspotPlace">
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <ImageSlider class="image-slider" :images="storyspotPlace.storyspot.images"/>
                  <h1 style="display: flex; justify-content: center">
                    <div v-if="!this.storyspotPlace.storyspot.isActive" class="not-active">
                      <md-icon class="icon">visibility_off</md-icon>
                      <md-tooltip md-delay="300" md-direction="right">This storyspot is
                        {{ this.storyspotPlace.storyspot.status.name }} and not
                        visible to other users
                      </md-tooltip>
                    </div>
                    {{ storyspotPlace.storyspot.name }}
                  </h1>
                  <div class="blockquote subheader large-paragraph">
                    <small>
                      {{ getAuthorUsername }} <strong>• </strong> {{ storyspotPlace.storyspot.durationTime }}
                      <strong>• </strong> {{ storyspotPlace.storyspot.langString }}
                      <strong> • </strong>{{ storyspotPlace.storyspot.currentDateString }}
                      <strong> • </strong>
                      <a class="map-marker" @click="showMap">
                        <md-icon>map</md-icon>
                      </a>
                    </small>
                  </div>
                </div>
              </div>
              <div class="blockquote category-tag">
                <small>
                  <badge :style="`background-color: ${storyspotPlace.storyspot.category.color}; font-size: 14px;`">
                    {{ storyspotPlace.storyspot.category.name }}
                  </badge>
                </small>
              </div>
              <div class="md-layout margin-top">
                <div
                    class="md-layout-item md-size-100"
                >
                  <div class="margin-b">
                    <div class="buttons">
                      <div class="mini-list">
                        <p class="large-paragraph">{{ storyspotPlace.storyspot.likes }}
                        <p/>
                        <md-icon style="margin-left: 8px">favorite</md-icon>
                      </div>
                      <md-button class="play-button md-just-icon md-round big-icon" @click="playAudio">
                        <md-icon>play_arrow</md-icon>
                      </md-button>
                      <div class="dropdown-container">
                        <li class="md-list-item">
                          <a
                              href="javascript:void(0)"
                              class="md-list-item-router md-list-item-container md-button-clean dropdown"
                          >

                            <drop-down direction="down">
                              <md-icon>more_vert</md-icon>
                              <ul class="dropdown-menu dropdown-menu-right">
                                <li class="dropdown-header">Options</li>
                                <li>
                                  <a class="dropdown-item" @click="copyLink">
                                    <md-icon>link</md-icon>
                                    <p class="small-paragraph">Get link</p>
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" @click="showModal">
                                    <md-icon>qr_code_2</md-icon>
                                    <p class="small-paragraph">Create QR-code</p>
                                  </a>
                                </li>
                                <!-- <li class="dropdown-header">Settings</li>
                                <li>
                                  <router-link class="dropdown-item" :to="'/edit-storyspot/' + this.storyspotId">
                                    <md-icon>edit</md-icon>
                                    <p class="small-paragraph">Edit storyspot</p>
                                  </router-link>
                                </li> -->
                              </ul>
                            </drop-down>
                          </a>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="md-layout margin-top">
                  <div
                      class="md-layout-item md-size-100"
                  >
                    <p class="text-description margin-text text-left large-paragraph">
                      {{ storyspotPlace.storyspot.description }}
                    </p>
                    <div class="margin-top">
                      <p>Tags:</p>
                      <md-chip class="tag" v-for="tag in storyspotPlace.storyspot.tags" v-bind:key="tag">{{
                          tag
                        }}
                      </md-chip>
                    </div>
                  </div>
                </div>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-if="storyspotPlace && mapVisible" @close="closeMap">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeMap"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <Map class="map-holder" :sp="storyspotPlace"/>
      </template>
    </modal>
    <QrCode v-if="storyspotPlace" :storyspot="storyspotPlace.storyspot" :show="showQrModal" :close="closeModal"/>
  </div>
</template>
<script>
import sharedConfig from "../../../shared/shared-config"
import Badge from "../../../shared/components/Badge";
import ImageSlider from "../../../shared/components/ImageSlider";
import Map from "../../../shared/components/Map";
import Modal from "../../../shared/components/Modal";
import {copyString} from "../../../shared/utils/general-utils";
import Vue from "vue";
import QrCode from "../../../shared/components/QrCode";
import router from "../router";

export default {
  bodyClass: "Detailed-storyspot",
  components: {
    Badge,
    ImageSlider,
    Map,
    Modal,
    QrCode
  },
  data() {
    return {
      storyspotId: null,
      mapVisible: false,
      isLoading: true,
      storyspotPlace: null,
      error: null,
      showQrModal: false,
    }
  },
  mounted() {
    this.storyspotId = this.$route.params.id;
    if (this.storyspotId) {
      this.isLoading = true;
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST, this.storyspotId).then((data) => {
        if (data.storyspot.author.id === this.currentUser.id || this.isAdmin) {
          this.storyspotPlace = data;
          document.title = this.storyspotPlace.storyspot.name + " - Storyspot";
          this.isLoading = false;
        } else {
          router.push('/');
        }
      }).catch((e) => {
        this.isLoading = false;
        this.error = e;
      })
    }
  },
  methods: {
    playAudio() {
      this.$store.dispatch(sharedConfig.store.audioPlayer.SET_AUDIO, this.storyspotPlace.storyspot.audio);
    },
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    showMap() {
      this.mapVisible = true;
    },
    closeMap() {
      this.mapVisible = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspotPlace.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    getAuthorUsername() {
      return this.storyspotPlace ? this.storyspotPlace.storyspot.author.fullName ? this.storyspotPlace.storyspot.author.fullName : this.storyspotPlace.storyspot.author.username : '';
    },

  }
}
</script>
<style lang="scss" scoped>
.main {
  min-height: 80vh;
  z-index: 0;
}

.category-tag {
  margin-top: 14px;
}

.play-button {
  background-color: #00434F !important;
}

.carousel {
  height: 25vh !important;
}

.subheader {
  font-size: 22px;
}

.margin-top {
  margin-top: 50px;
}

.blockquote strong {
  margin: 0 15px 0 15px;
}

.big-icon {
  margin-left: 50px;
  margin-right: 50px;
  width: 80px !important;
  height: 80px !important;
  border-right: 35px !important;
  font-size: 100px !important;
}

.md-button.md-round, .md-button.md-round.md-fab, .md-button.md-round.md-just-icon {
  border-radius: 40px;
}

.md-button.md-fab i, .md-button.md-fab, .md-button.md-just-icon i, .md-button.md-just-icon {
  font-size: 30px !important;
}

.margin-text {
  max-width: 600px;
  min-height: 200px;
  margin: auto;
}

.margin-b {
  max-width: 400px;
  margin: auto;

}

.buttons {
  display: flex;
  justify-content: center;
}

.map-holder {
  height: 50vh !important;
}

.map-marker {
  cursor: pointer;
}


.dropdown-container {
  margin: auto;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.not-active {
  float: left;
  padding-right: 8px
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.tag {
  background-color: #4297a6 !important;
  color: white !important;
}

.mini-list {
  margin: auto 12px auto auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-top: 10px;
  }
}
</style>