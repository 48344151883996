<template>
  <div style="margin-top: 25px;">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 md-small-size-100 md-small-size-100 md-medium-size-100">
        <tabs
            :tab-name="['Search location', 'Location by coordinates']"
            :tab-icon="['search', 'place']"
            plain
            nav-pills-icons
            :initTab="initTab"
            color-button="primary"
        >
          <template slot="tab-pane-1">
            <md-field class="md-form-group" :class="{ 'md-invalid': valid }">
              <md-icon>search</md-icon>
              <gmap-autocomplete
                  class="md-input"
                  :placeholder="'Search location'"
                  :v-model="locationSearch"
                  @place_changed="setPlace"
              >
              </gmap-autocomplete>
              <span class="md-error">{{ valid }}</span>
            </md-field>
          </template>
          <template slot="tab-pane-2">
            <md-field class="md-form-group" :class="{ 'md-invalid': locationValidation.lat }" clearable>
              <md-icon>east</md-icon>
              <md-input
                  v-model="latitude"
                  :placeholder="'Latitude'"
              ></md-input>
              <span class="md-error">Invalid latitude format ( must be between -90 and 90 )</span>
            </md-field>
            <md-field class="md-form-group" :class="{ 'md-invalid': locationValidation.lng }" clearable>
              <md-icon>north</md-icon>
              <md-input
                  v-model="longitude"
                  :placeholder="'Longitude'"
              ></md-input>
              <span class="md-error">Invalid longitude format ( must be between -180 and 180 )</span>
            </md-field>
          </template>
        </tabs>
        <GmapMap
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 300px"
            :options="mapOptions"
        >
          <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m"
              :icon="icon"
              @click="center = m"
          />
        </GmapMap>
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "./Tabs";
import {LatLng} from "../models/LatLng.model";

export default {
  components: {
    Tabs
  },
  props: {
    setInitPlace: {
      type: Object,
      default: null,
    },
    setLocation: {
      type: Function,
      default: null,
    },
    markerColor: {
      type: String,
      default: "#269da8",
    },
    valid: {
      type: String,
      default: null,
    },
    initValue: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      initTab: this.initValue ? 1 : 0,
      locationSearch: '',
      location: {
        lat: null,
        lng: null,
      },
      locationValidation: {
        lat: null,
        lng: null
      },
      gettingLocation: false,
      userLocation: null,
      currentPlace: null,
      center: {lat: 59.33459, lng: 18.06324},
      markers: [],
      zoom: 7,
      cordError: "",
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "all",
            elementType: "geometry.fill",
            stylers: [
              {
                weight: "2.00"
              }
            ]
          },
          {
            featureType: "all",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#9c9c9c"
              }
            ]
          },
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 45
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#7b7b7b"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#46bcec"
              },
              {
                visibility: "on"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c8d7d4"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#070707"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
      let cords = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      this.center = cords;
      this.markers = [cords];
      this.zoom = 11;
      let details = {
        position: cords,
        address_components: place.address_components,
        formatted_address: place.formatted_address,
        name: place.name,
        vicinity: place.vicinity,
      }
      this.setLocation(details);
    },
    setManual(location) {
      let {lat, lng} = location;
      this.currentPlace = null;
      let cords = {
        lat: lat,
        lng: lng
      };
      this.center = cords;
      this.markers = [cords];
      this.zoom = 11;
      let details = {
        position: cords,
        address_components: null,
        formatted_address: null,
        name: null,
        vicinity: null,
      }
      this.setLocation(details);
    }

  },
  mounted() {
    if (this.initValue && this.initValue.lat && this.initValue.lng) {
      this.location.lat = this.initValue.lat;
      this.location.lng = this.initValue.lng;
      this.setManual(this.initValue);
    } else {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
          pos => {
            this.gettingLocation = false;
            this.userLocation = pos;
            this.center = {lat: pos.coords.latitude, lng: pos.coords.longitude};

          },
          err => {
            this.gettingLocation = false;
            console.log(err);
          }
      );
    }
  },
  computed: {
    latitude: {
      get() {
        return this.location.lat;
      },
      set(newValue) {
        if (newValue && isFinite(newValue) && Math.abs(newValue) <= 90) {
          this.location.lat = parseFloat(newValue);
          this.locationValidation.lat = false;
          if (!this.locationValidation.lng && this.location.lng) {
            this.setManual(this.location)
          }
        } else {
          this.location.lat = null;
          this.locationValidation.lat = true;
        }
      }
    },
    longitude: {
      get() {
        return this.location.lng;
      },
      set(newValue) {
        if (newValue && isFinite(newValue) && Math.abs(newValue) <= 180) {
          this.location.lng = parseFloat(newValue);
          this.locationValidation.lng = false;
          if (!this.locationValidation.lat && this.location.lat) {
            this.setManual(this.location)
          }
        } else {
          this.location.lng = null;
          this.locationValidation.lng = true;
        }
      },
    },
    icon() {
      return {
        path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        fillColor: this.markerColor,
        fillOpacity: 1.0,
        strokeWeight: 1,
        scale: 0.5,
        strokeColor: "#ffffff"
      };
    },
  },
}
</script>
<style lang="scss" scoped>
</style>