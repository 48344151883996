import axios from "axios";
import storage from "../utils/localstorage-handler";
import configShared from "../shared-config";
import sharedConfig from "../shared-config";

const HTTP = axios.create({
    baseURL: "https://production-v2-dot-mindful-life-267318.appspot.com/api/v1/"
    //baseURL: "http://localhost:3000/api/v1/"
    // baseURL: "https://story-spot-api-v2-dot-mindful-life-267318.appspot.com/api/v1/"
});

// Interceptor on request, set tokens.
HTTP.interceptors.request.use(request => {
    const token = storage.get(configShared.storage.token);
    const refreshToken = storage.get(configShared.storage.refreshToken);
    if (token) {
        request.headers.common["Authorization"] = "Bearer " + token;
    }

    if (refreshToken) {
        request.headers.common["refresh_token"] = refreshToken;
    }
    return request;
});

// Interceptor on response, update tokens..
HTTP.interceptors.response.use(
    response => {
        if (response && response.headers) {
            if (response.headers.token) {
                storage.set(configShared.storage.token, response.headers.token);
            }
            if (response.headers.refresh_token) {
                storage.set(
                    configShared.storage.refreshToken,
                    response.headers.refresh_token
                );
            }
            if (response && response.status && response.status === 401) {
                this.$store.dispath(sharedConfig.store.actions.AUTH.LOGOUT_REQUEST);
            }
        }
        return Promise.resolve(response);
    },
    error => {
        console.log("response error", error);
        return Promise.reject(error);
    }
);

export default HTTP;
