<template>
  <div>
    <div id="material-kit">
      <div :class="{ 'nav-open': NavbarStore.showNavbar }">

        <router-view name="header"/>
        <div style="height: 100px"/>
        <router-view/>
        <AudioPlayer class="audio-player on-top-player"/>
        <router-view name="footer"/>

      </div>

    </div>
  </div>
</template>

<script>
import sharedConfig from "../../shared/shared-config"
import AudioPlayer from "../../shared/components/AudioPlayer";

export default {
  name: 'App',
  components: {
    AudioPlayer
  },
  mounted() {
    if (!window.location.href.indexOf('/login') > -1) {
      this.$store.dispatch(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
    }
  }
}
</script>
<style>

.main-content {
  margin-top: 60px;
}

.on-top-player {
  position: fixed;
  bottom: 25px;
  right: 25px;
  height: 60px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .on-top-player {
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .on-top-player {
    width: 100%;
  }
}
</style>
