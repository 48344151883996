import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import auth from "./auth";
import createStoryspot from "./createStoryspot";
import editStoryspot from "./editStoryspot";
import storyspots from "./storyspot";
import verifications from "./verifications";
import player from "../../../shared/store/player-store";
import staticStore from "../../../shared/store/static-store";
import otherStore from "../../../shared/store/other-store";

export default new Vuex.Store({
    modules: {
        auth,
        storyspots,
        player,
        staticStore,
        createStoryspot,
        editStoryspot,
        verifications,
        otherStore
    }
});
