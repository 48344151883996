<template>
  <md-chips class="md-primary shake-on-error" md-deletable :md-auto-insert="true" v-model="value"
            md-placeholder="Add tags...">
    <md-icon>tag</md-icon>
    <md-tooltip md-delay="300">Write a word and press enter to add it as a tag <i>( You may enter multiple tags )</i>
    </md-tooltip>
  </md-chips>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tooltipActive: true
    }
  }
}
</script>
<style lang="scss" scoped>
.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}
</style>