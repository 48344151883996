export const getDuration = function(file) {
    const reader = new FileReader();
    const audio = document.createElement("audio");
    return new Promise((resolve, reject) => {
        reader.onload = function(e) {
            audio.src = e.target.result;
            audio.addEventListener(
                "durationchange",
                function() {
                    const seconds = audio.duration;
                    resolve(seconds);
                },
                false
            );
            audio.addEventListener(
                "onerror",
                function() {
                    reject("Cannot get duration of this file.");
                },
                false
            );
        };
        reader.readAsDataURL(file);
    });
};