<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <div class="header">
              <h1>
                Feedback
              </h1>
              <p>
                We always strive to become better, and we love to hear your feedback!
              </p>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <md-field :class="{ 'md-invalid': isValid }">
                  <md-icon class="icon-textarea">feedback</md-icon>
                  <label class="label-textarea">{{!hasEdited ? 'Write a descriptive feedback message' : 'Feedback'}}</label>
                  <md-textarea v-model="feedback" type="text"></md-textarea>
                  <span class="md-error">{{ isValid}}</span>
                </md-field>
                <md-button class="md-primary feedback-button" :disabled="hasEdited && !!isValid" @click="sendFeedbackForm">Send feedback</md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedConfig from "../../../shared/shared-config";
import Vue from "vue";

export default {
  bodyClass: "Send-Feedback",
  components: {},
  created() {
    document.title = "Send Feedback - Storyspot";
  },
  data() {
    return {
      hasEdited: false,
    };
  },
  mounted() {
  },
  methods: {
    sendFeedbackForm() {
      this.$loading(true);
      this.$store.dispatch(sharedConfig.store.other.SEND_FEEDBACK).then(() => {
        this.$loading(false);
        const instance = Vue.$toast.open({
          message: "Thank you! We will get back to you as soon as possible",
          type: "success",
          position: "top-right"
        });
      }).catch((e) => {
        this.$loading(true);
        const instance = Vue.$toast.open({
          message: "Could not send feedback",
          type: "error",
          position: "top-right"
        });
      });
    },
  },
  computed: {
    feedback: {
      get() {
        return this.$store.getters.feedbackMessage;
      },
      set(newValue) {
        this.hasEdited = newValue && newValue.length > 0;
        this.$store.dispatch(sharedConfig.store.other.SET_FEEDBACK, newValue);
      }
    },
    isValid() {
      if (!this.hasEdited) {
        return null;
      } else {
        return this.$store.getters.feedbackValid;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-textarea {
  padding-left: 22px !important;
  min-height: 300px !important;
}
.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}
.label-textarea {
  margin-left: 33px;
}
.feedback-button {
  margin-top: 44px;
}
</style>
