import {Status} from "./Status.model";

export class Category {
    constructor(json) {
        try {
            this.id = json.id != null ? json.id : "";
            this.name = json.name != null ? json.name : "";
            this.color = json.color != null ? json.color : "#269da8";
            this.order = json.order != null ? json.order : 100;
            this.status = json.status != null ? new Status(json.status) : null;
            this.q = this.id + ' ' + this.name + ' ' + this.color + ' ' + this.order + ' ' + this.status.name;
        } catch (e) {
            
        }
    }
}
