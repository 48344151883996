<template>
  <div>
    <md-field>
      <label :for="label">{{ label }}</label>
      <md-icon v-if="icon">{{ icon }}</md-icon>
      <md-select v-model="value" :name="label" :id="label" :placeholder="placeholder" required>
        <md-option v-for="option in options" v-bind:key="option.id" :value="option.name">
          {{ option.name }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      placeholder: this.label,
    };
  },
}
</script>
<style lang="scss" scoped>
label {
  padding-left: 40px !important;
}

.md-menu.md-select {
  margin-left: 20px;
}
</style>