<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div v-if="!isAuthenticated" class="container">
            <div class="header">
              <h1>
                Storyspot - Account deletion
              </h1>
              <p>
                We noticed that you're not currently logged in. To proceed with an account deletion request, please log in to your account.<br>This step ensures the security and privacy of your account information. Once logged in, you'll be able to submit your account deletion request with ease. Thank you for ensuring the safety of your account!
              </p>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <md-button class="md-primary md-lg" @click="goToLogin">Go to login</md-button>
              </div>
            </div>
          </div>
          <div v-if="isAuthenticated && !hasProcessed" class="container">
            <div class="header">
              <h1>
                Storyspot - Account deletion
              </h1>
              <p>
                We understand the importance of this decision and want to assure you that your request is being processed with the utmost care. Please note that account deletion is a significant action and may take up to 30 days to complete. This period allows us to securely and thoroughly remove your data from our systems.<br><br>

                During this time, if you have any second thoughts or questions, please don't hesitate to reach out to us. We're here to assist you every step of the way. Your privacy and satisfaction are our top priorities. Thank you for your patience and understanding.
              </p>
            </div>
            <div class="md-layout">
              <md-button class="md-danger md-lg" @click="requestDeletion">Request account deletion</md-button>
            </div>
          </div>
          <div v-if="hasProcessed" class="container">
            <div class="header">
              <h1>
                Storyspot - Account deletion
              </h1>
              <p>
                We understand the importance of this decision and want to assure you that your request is being processed with the utmost care. Please note that account deletion is a significant action and may take up to 30 days to complete. This period allows us to securely and thoroughly remove your data from our systems.<br><br>

                We have received your request and will get back to you when the process is completed. <strong>You will not be able to use your account during this time.</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedConfig from "../../../../shared/shared-config";
import router from "../../router";
import Vue from "vue";

export default {
  bodyClass: "Send-Feedback",
  components: {},
  created() {
    document.title = "Account deletion - Storyspot";
  },
  data() {
    return {
      hasProcessed: false,
    };
  },
  mounted() {
  },
  methods: {
    goToLogin() {
      router.push('/login');
    },
    requestDeletion() {
      this.$loading(true);
      this.$store.dispatch(sharedConfig.store.profile.DELETE_ACCOUNT).then(() => {
        this.hasProcessed = true;
        this.$loading(false);
        const instance = Vue.$toast.open({
          message: "Thank you! We will get back to you as soon as possible",
          type: "success",
          position: "top-right"
        });
      }).catch((e) => {
        this.$loading(false);
        const instance = Vue.$toast.open({
          message: "Could not send request",
          type: "error",
          position: "top-right"
        });
      });
    },
  },
  computed: {
    feedback: {
      get() {
        return this.$store.getters.feedbackMessage;
      },
      set(newValue) {
        this.hasEdited = newValue && newValue.length > 0;
        this.$store.dispatch(sharedConfig.store.other.SET_FEEDBACK, newValue);
      }
    },
    isValid() {
      if (!this.hasEdited) {
        return null;
      } else {
        return this.$store.getters.feedbackValid;
      }
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  }
};
</script>

<style lang="scss" scoped>
.label-textarea {
  margin-left: 33px;
}
</style>
