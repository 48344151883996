import { Language } from "./Language.model";
import { Category } from "./Category.model";
import { CFile } from "./File.model";
import { Status } from "./Status.model";
import sharedConfig from "../shared-config";
import { convertToDateString } from "../utils/general-utils";
import {Author} from "./Author.model";
import {Storyspotter} from "./Storyspotter.model";

export class Storyspot {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.language = json.language ? new Language(json.language) : null;
        this.category = new Category(json.category);
        this.tags = json.tags;
        this._q = json.q != null ? json.q.toLowerCase() : json.q;
        this.author = new Author(json.author);
        this.images = json.image != null ? json.image.map((i) => new CFile(i)) : null;
        this.audio = json.audio ? new CFile(json.audio) : null;
        this.duration = json.duration;
        this.status = json.status ? new Status(json.status) : null;
        this.createdAt = json.createdAt ? new Date(json.createdAt) : null;
        this.updatedAt = json.updatedAt;
        this._listens = json.listens;
        this._likes = json.likes;
        this.storyspotter = json.storyspotter != null ? new Storyspotter(json.storyspotter) : null;
    }

    // TODO= these are for readability and are not to be changed
    get listens() {
        return this._listens || 0;
    }

    get q() {
        const query = this._q + this.status.name.toLowerCase();
        return query;
    }

    get likes() {
        return this._likes ? Array.isArray(this._likes) ? this._likes.length : 0 : 0;
    }

    get displayImage() {
        if (this.images && this.images.length > 0) return this.images[0].url;
        else return sharedConfig.default.image;
    }

    get displayImagePlaceholder() {
        if (this.images && this.images.length > 0) return this.images[0].url + '_150';
        else return sharedConfig.default.image;
    }

    get shortDescription() {
        return this.description
            ? this.description.length > 100
                ? this.description.slice(0, 100) + "..."
                : this.description
            : "";
    }

    get tagString() {
        return this.tags ? this.tags.toString() : "";
    }

    get langString() {
        return this.language.name.substring(0,3).toUpperCase();
    }

    get currentDateString() {
        return convertToDateString(this.createdAt);
    }

    get durationTime() {
        if (this.duration) {
            return new Date(this.duration * 1000).toISOString().substr(14, 5);
        } else {
            return 0;
        }
    }

    get originalDuration() {
        return this._duration;
    }

    get isActive() {
        return this.status.name.toLowerCase() == "Active".toLowerCase();
    }

}
