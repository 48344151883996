<template>
  <div>

    <md-autocomplete class="input-scope"
                     v-bind:class="{ 'md-invalid': invalid }"
                     :md-options="options"
                     :md-open-on-focus="true"

                     :value="value && value.name ? value.name : ''"
                     :v-model="value" @md-selected="didChange" md-fuzzy-search>
      <label>{{ label }}</label>
      <md-icon v-if="icon">{{ icon }}</md-icon>
      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
        <span class="color" :style="{'background-color': item.color ? item.color : null}"></span>
        {{ item.name }}
      </template>

      <template slot="md-autocomplete-empty" slot-scope="{ term }">
        No {{ label }} matching.
      </template>
      <span class="md-error">{{ isValid }}</span>
    </md-autocomplete>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => []
    },
    initValue: {
      type: Object,
      default: null
    },
    selected: {
      type: Function,
      default: null,
    },
    isValid: {
      type: String,
      default: null,
    }
  },
  mounted() {
    if (this.initValue) {
      this.value = this.initValue;
      this.selected(this.initValue);
    }
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    didChange(event) {
      this.value = event;
      this.selected(event);
    },
  },
  computed: {
    activeColor(item) {
      return item.color ? 'background-color: ' + item.color : null;
    },
    invalid() {
      if (this.isValid && this.isValid.length > 0) {
        return true;
      }
      return false;
    },
  }
};
</script>
<style lang="scss" scoped>
.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

label {
  padding-left: 40px !important;
}

.md-field.md-menu {
  padding-left: 40px !important;
}

.input-scope {
  padding-left: 40px !important;
  margin-right: 40px !important;
}

.md-icon {
  position: absolute !important;
  left: 0 !important;
}

</style>