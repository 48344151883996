import sharedConfig from "../../../shared/shared-config";
import baseAPI from "../../../shared/services/base-api-service"

const state = {
    isUploading: false,
    error: null,
    storyspot: {
        name: null,
        description: null,
        lang: null,
        category: null,
        images: null,
        audio: null,
        duration: null,
        position: null,
        tags: null,
        status: null,
        originalAudio: null,
        removeImage: [],
    },
    validators: {
        name: null,
        description: null,
        lang: null,
        category: null,
        images: null,
        audio: null,
        position: null,
        tags: null,
        status: null,
        originalAudio: null,
        removeImage: null,
    },
    errorMessage: null,
}

const getters = {
    createStoryspot(state) {
        return state.storyspot;
    },
    createStoryspotToFormData(state) {
        const formData = new FormData();
        formData.append("name", state.storyspot.name);
        formData.append("description", state.storyspot.description);
        formData.append("lang", state.storyspot.lang.id);
        formData.append("category", state.storyspot.category.id);
        formData.append("lat", state.storyspot.position.lat);
        formData.append("lon", state.storyspot.position.lng);
        formData.append("tags", state.storyspot.tags);
        formData.append("duration", state.storyspot.duration);
        formData.append("audio", state.storyspot.audio);
        if (state.storyspot && state.storyspot.images && state.storyspot.images.length > 0) {
            for (let i = 0; i < state.storyspot.images.length; i++)
                formData.append("images", state.storyspot.images[i]);
        }

        return formData;
    },
    isValid(state) {
        for (let i=0; i<Object.keys(state.validators).length; i++) {
            // empty all previus errors
            if (state.validators[Object.keys(state.validators)[i]] != null) {
                return false;
            }
        }
        return true;
    },
    validators(state) {
        return state.validators;
    }
}

const actions =  {
    [sharedConfig.store.createSpot.SET]: ({dispatch, commit, state}, obj) => {
        if (obj.key in state.storyspot) {
            commit(sharedConfig.store.createSpot.SET_KEY_VALUE, obj);
            dispatch(sharedConfig.store.createSpot.VALIDATE_FIELD, obj.key);
        }
    },
    [sharedConfig.store.createSpot.VALIDATE_FIELD]: ({commit, state}, key) => {

            if (key === 'name') {
                state.validators.name = validateName(state.storyspot.name);
            }
            if (key === 'description') {
                state.validators.description = validateDescription(state.storyspot.description);
            }
            if (key === 'lang') {
                state.validators.lang = validateLang(state.storyspot.lang);
            }
            if (key === 'category') {
                state.validators.category = validateCategory(state.storyspot.category)
            }
            if (key === 'audio') {
                state.validators.audio = validateAudio(state.storyspot.audio, state.storyspot.duration)
            }
            if (key === 'position') {
                state.validators.position = validatePosition(state.storyspot.position)
            }
    },
    [sharedConfig.store.createSpot.VALIDATE]: ({commit, state}) => {
        for (let i=0; i<Object.keys(state.validators).length; i++) {
            // empty all previous errors
            state.validators[Object.keys(state.validators)[i]] = null;
        }
        state.validators.name = validateName(state.storyspot.name)
        state.validators.description = validateDescription(state.storyspot.description);
        state.validators.lang = validateLang(state.storyspot.lang);
        state.validators.category = validateCategory(state.storyspot.category);
        state.validators.audio = validateAudio(state.storyspot.audio, state.storyspot.duration);
        state.validators.position = validatePosition(state.storyspot.position);
    },
    [sharedConfig.store.createSpot.UPLOAD]: ({dispatch, commit, state}) => {
        commit(sharedConfig.store.createSpot.UPLOAD);
        let data = getters.createStoryspotToFormData(state);
        return new Promise((resolve, reject) => {
            baseAPI
                .postFormData(sharedConfig.api.storyspotEndpoint, data)
                .then(resp => {
                    commit(sharedConfig.store.createSpot.UPLOAD_SUCCESS, resp);

                    let id = resp && resp.data && resp.data.storyspot && resp.data.storyspot.id ? resp.data.storyspot.id : null;
                    dispatch(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST).then(() => {
                        resolve(id);
                    })
                })
                .catch(e => {
                    commit(sharedConfig.store.createSpot.UPLOAD_ERROR, e);
                    reject(e);
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.createSpot.SET_KEY_VALUE]: (state, obj) => {
        state.storyspot[obj.key] = obj.value;
    },
    [sharedConfig.store.createSpot.UPLOAD]: (state) => {
        state.isUploading = true;
        state.error = null;
    },
    [sharedConfig.store.createSpot.UPLOAD_SUCCESS]: (state, data) => {
        state.isUploading = false;
    },
    [sharedConfig.store.createSpot.UPLOAD_ERROR]: (state, error) => {
        state.isUploading = false;
        state.error = error;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};


const validateName = function (name) {
    if (!name) {
        return "Name is required"
    } else if (name.length < 8) {
        return "Name needs to be at least 8 characters long"
    }
    return null;
}

const validateDescription = function (description) {
    if (!description) {
        return "Description is required"
    } else if (description.length < 10) {
        return "Description needs to be at least 10 characters long"
    }
    return null;
}

const validateCategory = function (category) {
    if (!category || !category.id) {
        return "A category is required";
    }
    return null;
}

const validateLang = function (language) {
    if (!language || !language.id) {
        return "A language is required";
    }
    return null;
}

const validateAudio = function (audio, duration) {
    if (!audio) {
        return "A audio file is required"
    } else if (!duration || duration <= 0) {
        return "Cannot read audio file, try a different format"
    }
    return null;
}

const validatePosition = function (position) {
    if (!position) {
        return "A location is requried";
    } else if (!position.lat || !position.lng) {
        return "There seems to be an error with the location"
    }
}