<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <h1>
              Edit storyspot
            </h1>
            <div v-if="isReady && !isLoading">
              <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <md-field required :class="{ 'md-invalid': validators.name }">
                    <md-icon>title</md-icon>
                    <label>Name</label>
                    <md-input v-model="story.name" type="text"></md-input>
                    <span class="md-error">{{ validators.name }}</span>
                  </md-field>
                  <md-field :class="{ 'md-invalid': validators.description }">
                    <md-icon class="icon-textarea">description</md-icon>
                    <label class="label-textarea">Description</label>
                    <md-textarea v-model="story.description" type="text"></md-textarea>
                    <span class="md-error">{{ validators.description }}</span>
                  </md-field>
                  <div v-if="categories != null && languages != null" class="margin-between">
                    <!-- AUDIO PLAYABLE -->
                    <audio controls class="audio-player" controlsList="nodownload">
                      <source :src="story.audio.url" />
                      Your browser does not support the audio element.
                    </audio>
                    <p class="edit-audio">You may change edit the audio of a storyspot</p>
                    <div class="margin-between"></div>
                    <Autocomplete :initValue="story.category" :value="story.category" :options="categories" label="Category" icon="category"
                                  :selected="setCategory" :isValid="validators.category"/>
                    <Autocomplete :initValue="story.language" :value="story.language" :options="languages" label="Language" icon="language"
                                  :selected="setLanguage" :isValid="validators.language"/>
                  </div>
                  <div class="margin-between">
                    <Tags :value="tags"/>
                    <Autocomplete :options="selectableStatuses" label="Visability" icon="visibility" :selected="setVisability" :initValue="story.status"/>
                  </div>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                  <ImageSelector :value="story.images" :setImages="setImages" :updateImage="updateImageAtIndex"/>
                  <MapSelector :setLocation="setLocation" :markerColor="markerColor" :valid="validators.position" :initValue="story.position"/>
                </div>
                <div class="md-layout-item md-size-100 m-top text-center">
                  <md-button class="md-primary md-lg" @click="update" :disabled="!canUpdate">Update</md-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Autocomplete from "../../../shared/components/Autocomplete";
import ImageSelector from "../../../shared/components/ImageSelector";
import Tags from "../../../shared/components/Tags";
import MapSelector from "../../../shared/components/MapSelector";
import SelectInput from "../../../shared/components/SelectInput";
import AudioSelector from "../../../shared/components/AudioSelector";
import sharedConfig from "../../../shared/shared-config";
import router from "../router";
import Vue from "vue";

export default {
  bodyClass: "edit-storyspots",
  components: {MapSelector, ImageSelector, Autocomplete, Tags, SelectInput, AudioSelector},
  created() {
    document.title = "Edit Storyspot - Organization";
  },
  data() {
    return {
      isLoading: true,
      storyspotId: null,
      images: {},
      removedImages: {},
      tags: []
    }
  },
  mounted() {
    this.$loading(true);
    this.storyspotId = this.$route.params.id;
    if (this.storyspotId) {
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST, this.storyspotId).then((data) => {
        this.$store.dispatch(sharedConfig.store.editSpot.SET_BASE, data);
        this.tags = data && data.storyspot && data.storyspot.tags ? data.storyspot.tags : [];
        // keeping local copy of images
        this.images = data.storyspot.images;
        document.title = 'Edit ' + data.storyspot.name + " - Storyspot";
        this.isLoading = false;
        this.$loading(false);
      }).catch((e) => {
        console.log(e);
        this.isLoading = false;
        this.error = e;
        this.$loading(false);
        router.replace('/my-storyspots')
      })
    }
  },
  watch: {
    'story.name': function(val, oldVal) {
      this.setValue(val, 'name');
    },
    'story.description': function(val, oldVal) {
      this.setValue(val, 'description');
    },
    tags: function (val, oldVal) {
      this.setValue(val, 'tags');
    }
  },
  methods: {
    async update() {
      let update = await this.$store.dispatch(sharedConfig.store.editSpot.UPDATABLE_VALUES);
      if (update) {
        this.$loading(true);
        this.$store.dispatch(sharedConfig.store.editSpot.UPDATE, {
          id: this.storyspotId,
          data: update
        }).then(() => {
          this.$loading(false);
          router.replace('/my-storyspots/' + this.storyspotId)
          const instance = Vue.$toast.open({
            message: "Update successful",
            type: "success",
            position: "top-right"
          });
        }).catch((e) => {
          this.$loading(false);
          const instance = Vue.$toast.open({
            message: "Update failed",
            type: "error",
            position: "top-right"
          });
        })
      } else {
        const instance = Vue.$toast.open({
          message: "Nothing to update",
          type: "success",
          position: "top-right"
        });
      }
    },
    setValue(value, key) {
      this.$store.dispatch(sharedConfig.store.editSpot.SET, {value, key});
    },
    async setImages(files, removed = []) {
      if (removed) {
        if (this.removedImages?.length > 0) {
          this.removedImages.concat(removed);
        } else {
          this.removedImages = removed;
        }

        for (let i = 0; i < removed.length; i++) {
          const index = this.images?.indexOf(a => a.id === removed[i]);
          this.images.slice(index, 1);
        }
      }
      const newImages = [];
      for (let i = 0; i < files.length; i++) {
        newImages.push(files[i]);
      }
      if (this.images?.length > 0) {
        this.images = this.images?.concat(newImages);
      } else {
        this.images = newImages;
      }
      this.setValue(this.removedImages, 'removeImage');
      this.setValue(this.images, 'images');
    },
    updateImageAtIndex(index, img) {
      if (this.images[index]) {
        this.images[index] = img;
      }
    },
    setCategory(category) {
      this.setValue(category, 'category');
    },
    setLanguage(language) {
      this.setValue(language, 'language');
    },
    setVisability(visability) {
      this.setValue(visability, 'status');
    },
    setLocation(place) {
      this.setValue(place.position, 'position');

    },
    setAudio(audio, duration) {
      this.setValue(duration, 'duration');
      this.setValue(audio, 'audio');
    }
  },
  computed: {
    canUpdate() {
      return this.$store.getters.canUpdateEditStoryspot;
    },
    story() {
      return this.$store.getters.editStoryspot;
    },
    validators() {
      return this.$store.getters.validators;
    },
    isReady() {
      return this.$store.getters.getHasStaticData;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    languages() {
      return this.$store.getters.getLanguages;
    },
    selectableStatuses() {
      return this.$store.getters.getSelectableStatuses;
    },
    getActiveValue() {
      return this.$store.getters.getSelectableStatuses.filter((a) => a.name.toLowerCase() === 'active')[0];
    },
    markerColor() {
      return this.story && this.story.category && this.story.category.color ? this.story.category.color : null;
    }
  }
}
</script>
<style lang="scss" scoped>
.m-top {
  margin:auto;
  margin-top: 80px;
}
.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}

.color {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 16px;
  border: 1px solid rgba(#000, .12);
}


.label-textarea {
  margin-left: 33px;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

textarea {
  height: 1200px !important;
  resize: none !important;
}

.md-textarea {
  padding-left: 22px !important;
}


.md-ripple {
  padding-right: 42px !important;
}

.margin-between {
  margin-top: 50px;
}

.md-invalid {
  margin-bottom: 25px !important;
}

.audio-player {
  width: 100vw;
  margin: auto;
  height: 50px;

}
.edit-audio {
  font-size: 0.8rem;
  font-style: italic;
  margin-left: 18px
}
</style>