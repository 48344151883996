<template>
  <div>
    <div class="main-content">
      <div class="wrapper">
        <div class="main main-raised">
          <div class="section">
            <div class="container text-center">
              <div v-if="!isLoading && storyspot">
                <div class="md-layout">
                  <div
                      class="md-layout-item md-size-100"
                  >
                    <ImageSlider class="image-slider" :images="storyspot.images"/>
                    <h1 style="display: flex; justify-content: center">
                      {{ storyspot.name }}</h1>
                    <div class="blockquote subheader large-paragraph">
                      <small>
                        {{ getAuthorUsername }} <strong>• </strong> {{ storyspot.durationTime }}
                        <strong>• </strong> {{ storyspot.langString }}
                        <strong> • </strong>{{ storyspot.currentDateString }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="blockquote category-tag">
                  <small>
                    <badge :style="`background-color: ${storyspot.category.color}; font-size: 14px;`">
                      {{ storyspot.category.name }}
                    </badge>
                  </small>
                </div>
                <div class="md-layout margin-top">
                  <div
                      class="md-layout-item md-size-100"
                  >
                    <p class="text-description margin-text text-left large-paragraph">
                      {{ storyspot.description }}
                    </p>
                    <h3>Listen on</h3>
                    <div class="row" style="display: flex; justify-content: center; align-content: center;">
                      <a href="https://apps.apple.com/se/app/storyspot-uppt%C3%A4ck-platser/id1090760665?ign-itscg=30200&ign-itsct=apps_box_link" target="_blank">
                      <img class="image-badge"
                           style="width: auto; max-width: 20vh; height: 50px !important; margin-top: 12px"
                           src="../../../shared/assets/images/app_store_badge_en.svg"/>
                      </a>

                      <a href="https://play.google.com/store/apps/details?id=se.storyspot.android.StorySpot&gl=SE" target="_blank">
                        <img
                          class="image-badge"
                          style="width: auto; max-width: 20vh; height: 75px !important;"
                          src="../../../shared/assets/images/google-play-badge_en.png"/>
                      </a>
                    </div>
                    <div class="margin-top">
                      <p>Tags:</p>
                      <md-chip class="tag" v-for="tag in storyspot.tags" v-bind:key="tag">{{ tag }}</md-chip>
                    </div>
                  </div>
                </div>
                <div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 100px"></div>
  </div>
</template>
<script>
import sharedConfig from "../../../shared/shared-config"
import Badge from "../../../shared/components/Badge";
import ImageSlider from "../../../shared/components/ImageSlider";
import Map from "../../../shared/components/Map";
import Modal from "../../../shared/components/Modal";
import {copyString} from "../../../shared/utils/general-utils";
import Vue from "vue";
import QrCode from "../../../shared/components/QrCode";
import router from "../router";

export default {
  bodyClass: "seo-storyspot",
  components: {
    Badge,
    ImageSlider,
    Map,
    Modal,
    QrCode
  },
  data() {
    return {
      storyspotId: null,
      isLoading: true,
      storyspot: null,
      error: null,
      isIphone: false,
      isAndroid: false,
    }
  },
  mounted() {
    const userAgent = window.navigator.userAgent;
    this.isIphone = userAgent.match(/iPhone/);
    this.isAndroid = userAgent.match(/Android/);

    this.storyspotId = this.$route.params.id;
    if (this.storyspotId) {
      this.isLoading = true;
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_SEO_REQUEST, this.storyspotId).then((data) => {
        this.storyspot = data;
        document.title = this.storyspot.name + " - Storyspot";
        this.isLoading = false;
      }).catch((e) => {
        this.isLoading = false;
        this.error = e;
        console.log(e);
      })
    }
  },
  methods: {
    copyLink() {
      let link = "https://app.storyspot.se/share/s" + this.storyspot.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  },
  computed: {
    getAuthorUsername() {
      return this.storyspot ? this.storyspot.author.fullName ? this.storyspot.author.fullName : this.storyspot.author.username : '';
    },

  }
}
</script>
<style lang="scss" scoped>
.main {
  min-height: 80vh;
  z-index: 0;
}

.category-tag {
  margin-top: 14px;
}

.play-button {
  background-color: #00434F !important;
}

.carousel {
  height: 25vh !important;
}

.subheader {
  font-size: 22px;
}

.margin-top {
  margin-top: 50px;
}

.blockquote strong {
  margin: 0 15px 0 15px;
}

.big-icon {
  margin-left: 50px;
  margin-right: 50px;
  width: 80px !important;
  height: 80px !important;
  border-right: 35px !important;
  font-size: 100px !important;
}

.md-button.md-round, .md-button.md-round.md-fab, .md-button.md-round.md-just-icon {
  border-radius: 40px;
}

.md-button.md-fab i, .md-button.md-fab, .md-button.md-just-icon i, .md-button.md-just-icon {
  font-size: 30px !important;
}

.margin-text {
  max-width: 600px;
  min-height: 200px;
  margin: auto;
}

.margin-b {
  max-width: 400px;
  margin: auto;

}

.buttons {
  display: flex;
  justify-content: center;
}

.map-holder {
  height: 50vh !important;
}

.map-marker {
  cursor: pointer;
}


.dropdown-container {
  margin: auto;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.not-active {
  float: left;
  padding-right: 8px
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.tag {
  background-color: #4297a6 !important;
  color: white !important;
}

.mini-list {
  margin: auto 12px auto auto;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    padding-top: 10px;
  }
}
</style>