<template>
  <modal v-if="show" @close="close">
    <template slot="header">
      <h4 class="modal-title">{{ storyspot.name }}</h4>
      <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="close"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <div id="printMe">
        <div class="printMe">
          <center>
            <div class="row" style="display: flex; justify-content: center; align-content: center;">
              <img class="logo-img" style="width: 300px;" src="@/assets/logo_480.png"/>
                <qrcode-vue class="qr-code" style="margin-top: 13px" :render-as="'svg'"
                            :value="'https://app.storyspot.se/share/s' + storyspot.id"
                            :size="275" level="H"/>

            </div>
            <h3 style="margin: 45px 0 45px 0; font-size: 45px; font-family: 'Raleway', sans-serif; font-weight: 400">
              {{ storyspot.name }}</h3>
            <div class="row" style="display: flex; justify-content: center; align-content: center;">
              <img class="image-badge"
                   style="width: auto; max-width: 20vh; height: 50px !important; margin-top: 12px"
                   src="../assets/images/app_store_badge_en.svg"/>

              <img class="image-badge"
                   style="width: auto; max-width: 20vh; height: 75px !important;"
                   src="../assets/images/google-play-badge_en.png"/>
            </div>
          </center>
        </div>

      </div>
      <qrcode-vue class="qr-code"
                  :value="'https://app.storyspot.se/share/s' + storyspot.id"
                  :size="275" level="H"/>
      <md-button @click="print" class="md-primary">
        <md-icon>print</md-icon>
        Print
      </md-button>
    </template>

  </modal>
</template>
<script>
import Modal from "./Modal";
import {Storyspot} from "../models/Storyspot.model";
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    Modal,
    QrcodeVue
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
    },
    storyspot: {
      type: Storyspot,
      default: null,
    }
  },
  data() {
    return {
      classicModal: this.show,
    };
  },
  methods: {
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe');
    },
  }
};
</script>
<style lang="scss" scoped>
.qr-code {
  padding: 20px !important;
}

.printMe {
  display: none;
  .logo-img {
    width: 350px;
  }

  .row {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-spacing: 10px;
  }

  .column {
    display: table-cell;
  }

  .image-badge {
    width: 20vh;
  }
}
</style>