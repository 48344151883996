<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 mx-auto md-small-size-100">
      <md-card>
        <carousel
            :per-page="1"
            loop
            :speed="700"
            autoplay
            :autoplay-timeout="5000"
            :mouse-drag="false"
            navigationEnabled
            :centerMode="true"
            navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
            navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
        >
          <slide v-for="img in images" v-bind:key="img.id">
            <img :src="img.url" :alt="img.id"/>
          </slide>
        </carousel>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => []
    },
  }
}
</script>
<style lang="scss" scoped>
.md-card img {
  min-height: 200px;
  max-height: 350px;
  width: auto;
  object-fit: contain;
}
.md-card {
  box-shadow: unset;
}
</style>