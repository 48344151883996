import baseAPi from "./base-api-service"
import shaerdConfig from "../shared-config";
import baseAPI from "./base-api-service";
import sharedConfig from "../shared-config";

export default {
    //
    //
    // Login
    forgotPassword: async function (email) {
       return await baseAPI
            .postData(sharedConfig.api.authEndpoint + sharedConfig.api.forgotPassword, {email: email})
    },
    verifyEmail: async function (token) {
        return await baseAPI
            .getData(sharedConfig.api.verifyEmail + token)
    }
}